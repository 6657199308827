
  import { defineComponent } from 'vue';

  import DotsIcon from '@/components/icons/dots.vue';

  export default defineComponent({
    name: 'hero-landing',
    components: {
      'dots-icon': DotsIcon,
    },
    data() {
      return {
        currentImage: 0,
      };
    },
    methods: {
      setImages(threshold: number = 3) {
        setInterval(() => {
          if (this.currentImage === threshold) {
            this.currentImage = 0;

            return;
          }

          this.currentImage += 1;
        }, 500);
      },
      setScrollBehaviorSmoothToHTML() {
        const html = document.querySelector('html');

        if (html) {
          html.style.scrollBehavior = 'smooth';

          setTimeout(() => {
            html.style.scrollBehavior = 'auto';
          }, 1000);
        }
      },
    },
    created() {
      this.setImages();
    },
  });
