<template>
    <svg
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle 
            cx="12" 
            cy="4" 
            r="4"
            fill="#F9E44D"
        />
        <circle 
            cx="20" 
            cy="12" 
            r="4" 
            transform="rotate(90 20 12)" 
            fill="#F9E44D"
        />
        <circle 
            cx="12" 
            cy="20" 
            r="4" 
            fill="#F9E44D"
        />
        <circle 
            cx="4" 
            cy="12" 
            r="4" 
            transform="rotate(90 4 12)" 
            fill="#F9E44D"
        />
    </svg>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'dots-icon',
    });
</script>