
  import { defineComponent } from 'vue';
  
  import HeroLanding from '@/components/hero/HeroLanding.vue';
  // import ProjectBox from '@/components/project/ProjectBox.vue';

  export default defineComponent({
    name: 'Home',
    title() {
      return 'Lennart Hakker - Home';
    },
    components: {
      'hero-landing': HeroLanding,
      // 'project-box': ProjectBox,
    },
  });
